import axios from 'axios';
import React, { useCallback, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate
} from "react-router-dom";
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import LogoIcon from "../components/Atoms/LogoIcon";
import Button from "../components/Atoms/Button";

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    axios.get(`https://inkless.app/api/session-status?session_id=${sessionId}`)
      .then((res) => {
        const data = res.data;
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      })
      .catch((error) => {
        console.error("There was an error fetching the session status!", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-background">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (status === 'complete') {
    return (
      <div className="min-h-screen bg-background flex flex-col items-center justify-center px-4">
        <div className="flex flex-col items-center justify-center gap-4 max-w-md w-full bg-gray shadow-lg rounded-lg p-8 text-center">
          <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-primary/10 mb-4">
            <CheckCircleIcon className="h-8 w-8 text-primary" />
          </div>
          
          <h2 className="text-2xl font-bold text-textPrimary">
            Thank you for your purchase!
          </h2>
          
          <p className="text-textSecondary">
            We appreciate your business! A confirmation email will be sent to{' '}
            <span className="font-medium text-textPrimary">{customerEmail}</span>
          </p>

          <Button
            label={"Start Your Next Adventure"}    
            bgColor='bg-primary'        
            textColor="text-texttertiary" 
            border="border-primary"
            onClick={() => navigate('/campaigns')}
          />
          
          <div className="border-t border-divider pt-6">
            <p className="text-sm text-textSecondary">
              Have questions?{' '}
              <a 
                href="mailto:orders@example.com" 
                className="text-primary hover:text-primary/80 font-medium"
              >
                Contact our support team
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Return;