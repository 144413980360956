import React, {
  Fragment,
  useState,
  useEffect,
} from "react";
import {
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import {
  FolderIcon,
} from "@heroicons/react/24/outline";
import { Dialog, Transition, Combobox } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { useCampaignContext } from "../../contexts/CampaignContext";
import NoteSearchResult from "../Molecules/NoteSearchResult";
import { useNavigate, useParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Search() {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const campaignContext = useCampaignContext();
  const { currentCampaignId, getKeys, getNoteTypes, getSessions, getSearchResults, currentTheme } = campaignContext;
  const navigate = useNavigate();

  const { campaignId, id } = useParams();

  const [open, setOpen] = useState(false);  
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const KeysQuery = useQuery({ queryKey: ["Keys"], queryFn: () => getKeys(campaignId)});
  const NoteTypesQuery = useQuery({ queryKey: ["NoteTypes"], queryFn: () => getNoteTypes(campaignId)});
  const SessionsQuery = useQuery({ queryKey: ["Sessions", currentCampaignId], queryFn: () => getSessions(currentCampaignId)});

  useEffect(() => {
    const TimoutId = setTimeout(() => {
      handleGetSearchResult(searchValue);
    }, 400);
    return () => clearTimeout(TimoutId);
  }, [searchValue]);

  const handleGetSearchResult = async (value) => {
    if (isAuthenticated == false) return;

    if (value === null || value === "") {
      setSearchResults([]);      
      return;
    }    

    getSearchResults({ SearchText: value }).then((data) => {
      setSearchResults(data);      
    });
  };

  const goToSession = (Session, NoteId) => {
    setOpen(false);
    setSearchResults([]);    
    navigate(`/campaigns/${currentCampaignId}/session/${Session.Id}/${NoteId}`);
  }

  return (
    <>
      <div className="flex flex-1 justify-center px-2 lg:ml-6 lg:justify-end">
        <div className="w-52 max-w-lg lg:max-w-xs">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
                onClick={() => setOpen(true)}
              />
            </div>

            <span
              id="search"
              onClick={() => setOpen(true)}
              name="search"
              className="block w-52 rounded-md shadow shadow-shadow cursor-pointer bg-gray py-2 pl-10 pr-3 leading-5 
              text-textprimary placeholder-gray-400 focus:border-white focus:bg-white focus:text-gray-900 focus:outline-none focus:ring-white sm:text-sm"                            
            >Search
            </span>
            {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <kbd className="inline-flex items-center font-sans text-xs font-medium text-gray-400">
                Ctrl K
              </kbd>
            </div> */}
          </div>
        </div>
      </div>

      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setSearchValue("")}
        appear
      >
        <Dialog as="div" className="relative z-10" onClose={() => {setOpen(false); setSearchResults([])}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className={`Themed ${currentTheme?.Name || "Default"} fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20`}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto max-w-4xl h-5/6 transform overflow-hidden border-border border rounded-xl shadow-2xl transition-all">
                <Combobox>
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-textprimary text-opacity-40"
                      aria-hidden="true"
                    />
                    <Combobox.Input
                      className="h-12 w-full border-0 bg-background border-b border-border pl-11 pr-4 text-textprimary placeholder-textprimary focus:ring-0 sm:text-sm"
                      placeholder="Search..."                                            
                      onChange={(event) => setSearchValue(event.target.value)}
                      onSubmit={() => {return false}}
                    />
                  </div>

                  <Combobox.Options
                    static
                    className="scroll-py-2 py-2 overflow-hidden h-[calc(100%-3rem)] bg-background"
                  >
                    {
                    <div className="overflow-hidden h-full">
                        <h2 className="sr-only">Search Results</h2>
                        <ul className="text-sm text-textprimary bg-background divide-y divide-background flex flex-col h-full overflow-auto">
                          {searchResults.map((result, index) => {
                            
                            let session = SessionsQuery.data.find(x => x.Id == result.SessionId);
                            let sessionIndex = session.RowIndex;
                            
                            return (
                              <Combobox.Option
                                key={result.Id}
                                value={result}
                                className={({ active }) =>
                                  classNames(
                                    "flex cursor-pointer select-none items-center rounded-md p-1",
                                    active &&
                                      "bg-gray-900 bg-opacity-5 text-gray-900"
                                  )
                                }
                                onClick={(e) => {e.preventDefault(); goToSession(session, result.Id)}}
                              >
                                {({ active }) => (
                                  <div className="flex flex-row grow items-center">
                                    <div className="flex flex-row gap-2 grow items-center px-1">
                                      <p className="text-sm font-medium group-hover:text-white text-gray-500">
                                        {sessionIndex}
                                      </p>
                                      <NoteSearchResult
                                        key={index}
                                        note={result}
                                        index={index}
                                        keys={KeysQuery.data || []}
                                        noteTypes={NoteTypesQuery.data || []}
                                        searchTerm={searchValue}
                                      />
                                    </div>
                                    {active && (
                                      <span className="text-gray-500 min-w-max">
                                        Jump to...
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Combobox.Option>
                            );
                                  }
                          )}
                        </ul>                      
                    </div>
                    }
                  </Combobox.Options>   

                  {searchResults.length === 0 && (
                    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  py-14 px-6 text-center sm:px-14">
                      <MagnifyingGlassIcon
                        className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                        aria-hidden="true"
                      />
                <p className="mt-4 text-sm text-gray-900">
                  Roll a Perception Check...
                </p>
                    </div>
                  )}               
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
            
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
