import Pricing from '../components/Landing/Pricing.jsx';


export default function PricingPage() {
  return (
    <div className='overflow-auto'>      
      <Pricing/>
    </div>
  )
}
