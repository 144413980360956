import React, { lazy, Suspense, useState, useEffect, forwardRef } from "react";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import { useCampaignContext } from "../../contexts/CampaignContext";

import Loading from "../Atoms/Loading";
const Note = lazy(() => import("../Molecules/Note"));

const NoteList = forwardRef(({
  CampaignId,
  SessionNotesData,
  paramNoteId,
  NoteTypesQuery,
  KeysQuery,
  KeyWordsQuery,
  sortBy,
}, ref) => {
  const campaignData = useCampaignContext();
  const { useUpdateSortOrder } = campaignData;
  const { mutate: UpdateSortOrder } = useUpdateSortOrder();

  const [sessionNotesData, setSessionNotesData] = useState(SessionNotesData);

  useEffect(() => {
    setSessionNotesData(SessionNotesData);
  }, [SessionNotesData]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    // First sort the notes according to current sort order
    const sortedNotes = [...sessionNotesData].sort((a, b) => {
      if (sortBy === 'oldest') {
        return a.SortOrder - b.SortOrder;
      }
      return b.SortOrder - a.SortOrder;
    });

    const sourceNote = sortedNotes[source.index];

    // Remove source note from the sorted array
    sortedNotes.splice(source.index, 1);

    // Calculate the new sort order value based on sorted positions
    let newSortOrder;
    
    if (destination.index === 0) {
      // Moving to the start
      const before = sortedNotes[0];
      newSortOrder = before ? before.SortOrder + (sortBy === 'oldest' ? -1 : 1) : 1;
    } else if (destination.index >= sortedNotes.length) {
      // Moving to the end
      const after = sortedNotes[sortedNotes.length - 1];
      newSortOrder = after ? after.SortOrder + (sortBy === 'oldest' ? 1 : -1) : 1;
    } else {
      // Moving between two items
      const before = sortedNotes[destination.index - 1];
      const after = sortedNotes[destination.index];
      newSortOrder = (before.SortOrder + after.SortOrder) / 2;
    }

    // Update the backend
    const update = {
      noteId: sourceNote.Id,
      newSortOrder: newSortOrder
    };

    // Reinsert the note in the correct order\
    sourceNote.SortOrder = newSortOrder;
    const updatedSortedNotes = [...sortedNotes];
    updatedSortedNotes.splice(destination.index, 0, sourceNote);
    setSessionNotesData(updatedSortedNotes);

    UpdateSortOrder(update);
  };

  if (
    NoteTypesQuery.status !== "success" &&
    KeysQuery.status !== "success" &&
    KeyWordsQuery.status !== "sucess"
  )
    return <Loading />;

  // Sort notes only for display
  const sortedNotes = [...sessionNotesData].sort((a, b) => {
    if (sortBy === 'oldest') {
      return a.SortOrder - b.SortOrder;
    }
    return b.SortOrder - a.SortOrder;
  });

  return (
    <div id="NoteList" ref={ref} className="sm:py-4 py-0 flex flex-col gap-y-2 overflow-hidden h-full">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="NoteList">
          {(dropProvided) => (
            <div ref={dropProvided.innerRef} {...dropProvided.droppableProps} className="flex flex-col overflow-auto h-full">
              {sortedNotes &&
                sortedNotes.map((note, index) => {
                  let containerClassList = note.Id === Number(paramNoteId) ? "animate-pulse-3" : "";

                  return (
                    <Suspense key={index} fallback={<Loading />}>
                      <Note
                        key={index}
                        note={note}
                        index={index}
                        keys={KeysQuery.data || []}
                        noteTypes={NoteTypesQuery.data || []}
                        keysQuery={KeysQuery}
                        keyWordsQuery={KeyWordsQuery}
                        containerClassList={containerClassList}
                        campaignId={CampaignId}
                      />
                    </Suspense>
                  );
                })}              
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
});

export default NoteList;