import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useCampaignContext } from "../contexts/CampaignContext";
import { useParams,useNavigate } from 'react-router-dom';
import Loading from "../components/Atoms/Loading";
import Button from '../components/Atoms/Button';

export default function Join()
{    
    const campaignContext = useCampaignContext();        
    const { campaignId, code, permission } = useParams();
    const CampaignId = parseInt(campaignId);
    let navigate = useNavigate();
    
    const [joinedCampaign, setJoinedCampaign] = useState(null);
    const [hasJoined, setHasJoined] = useState(false);

    const campaignData = useCampaignContext();
    const { joinCampaign } = campaignData;    

    const { user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    useEffect(() =>
    {
        async function handleJoin()
        {
            if (!isAuthenticated || hasJoined)
                return;

            try
            {
                console.log('Join.js: handleJoin');
                setHasJoined(true);

                const token = await getAccessTokenSilently();

                let response = await joinCampaign(CampaignId, code, permission);

                if (response.status >= 200 && response.status < 300 && response.data.length)
                {
                    console.log('Join.js: handleJoin: response.data[0][0]');
                    setJoinedCampaign(response.data[0][0]);                    
                }
                else
                {
                    console.log('Join.js: handleJoin: navigate("/oops")');
                    navigate("/oops");
                    setHasJoined(false);
                }
            }
            catch (err)
            {
                setHasJoined(false);
                console.log('Error joining campaign: ' + err);
                navigate("/oops");
            }
        }

        handleJoin();
    }, [isAuthenticated, hasJoined, joinCampaign, CampaignId, code, permission]);

    if(isLoading || !joinedCampaign)
        return <Loading />

    if(!isAuthenticated)
    {
        loginWithRedirect({
            appState: {
                returnTo: window.location.pathname
            }
        })

        return <div>Redirecting...</div>
    }
    else{
        return (
            <main className="grid min-h-full place-items-center bg-background px-6 py-24 sm:py-32 lg:px-8">
                <div className="text-center">
                    <p className="text-base font-semibold text-green-400">Success! Welcome to </p>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-primary sm:text-5xl">{joinedCampaign?.Name}</h1>
                    <p className="mt-6 text-base leading-7 font-medium text-textprimary">Get Ready for Adventure</p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Button label={"Home"} onClick={() => navigate("/")} />
                        <a onClick={() => navigate("/campaigns/" + joinedCampaign?.Id)} className="text-sm font-semibold cursor-pointer text-textprimary">
                            View Campaign <span aria-hidden="true">&rarr;</span>
                        </a>
                    </div>
                </div>
            </main>
        );
    }

    
}
