import React from 'react';
import { Container, Typography, Box, Link, Paper } from '@mui/material';

const Support = () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          Support
        </Typography>
        
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            Contact Us
          </Typography>
          <Typography paragraph>
            If you need assistance or have any questions, you can reach us at:
            <br />
            Email: <Link href="mailto:support@inkless.app">support@inkless.app</Link>
          </Typography>
        </Paper>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h5" gutterBottom>
            FAQ
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            How do I create a new campaign?
          </Typography>
          <Typography paragraph>
            Click the "+" button in the top menu to create a new campaign. You'll be able to set a name and description for your campaign.
          </Typography>

          <Typography variant="h6" gutterBottom>
            How do I share my campaign with others?
          </Typography>
          <Typography paragraph>
            In your campaign settings, you'll find an invite code that you can share with other users. They can use this code to join your campaign.
          </Typography>

          <Typography variant="h6" gutterBottom>
            What are Keywords?
          </Typography>
          <Typography paragraph>
            Keywords are tags that you can add to your notes to help organize and find information later. They're especially useful for tracking important NPCs, locations, and items.
          </Typography>
        </Paper>

        <Paper sx={{ p: 3 }}>
          <Typography variant="h5" gutterBottom>
            Known Issues
          </Typography>
          <Typography paragraph>
            We maintain a list of known issues and their status. If you encounter a problem not listed here, please contact our support team.
          </Typography>
          <Typography component="ul">
            <li>Currently no known issues</li>
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Support; 