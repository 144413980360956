import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/App.scss';
import App from './App';
import { CampaignContextProvider } from './contexts/CampaignContext';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';
import { BrowserRouter } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <CampaignContextProvider>            
            <App />
          </CampaignContextProvider>
          {/* <ReactQueryDevtools initialIsOpen={false}/> */}
        </QueryClientProvider>
      </React.StrictMode>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
