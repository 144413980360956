import React, { Fragment, useState, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import IconCollection from "../../utilities/IconMap";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function NoteTypeList({ NoteTypes, onChange, defaultValue, fullWidth }) {
  const [selected, setSelected] = useState(NoteTypes[0]);

  useEffect(() => onChange(selected), [selected]);
  useEffect(() => {
    if(!defaultValue) return;

    let DefaultSelected = NoteTypes.find((x) => x.Id == defaultValue);
    setSelected(DefaultSelected);
  }, [defaultValue]);

  let SelectedNoteTypeIcon = null;

  if (NoteTypes.length > 0 && selected) {
    const noteType = NoteTypes.find((x) => x.Id == selected.Id);
    SelectedNoteTypeIcon = noteType?.Icon ? IconCollection.find(x => x.Name == noteType?.Icon).Icon : null;
  }

  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>          
          <div className="relative w-auto">
            <Listbox.Button className={`flex relative ${fullWidth ? 'w-full' : 'w-fit'} h-9 cursor-default rounded-md border border-border ThemeBackground bg-gray text-textprimary py-2 pl-3 pr-10 text-left shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm`}>
              <span className="flex items-center w-fit max-w-[160px]">
                {SelectedNoteTypeIcon ? <SelectedNoteTypeIcon className="w-[20px] min-w-[20px]" /> : null}
                <span className="ml-3 block truncate">{selected?.Name}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute bg-background border border-border z-10 mt-1 max-h-56 w-fit overflow-auto rounded-md py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {NoteTypes.map((notetype) => {
                  let NoteTypeIcon;
                  const noteType = NoteTypes.find((x) => x.Id == notetype.Id);
                  NoteTypeIcon = noteType?.Icon ? IconCollection.find(x => x.Name == noteType?.Icon).Icon : null;

                  return (
                  <Listbox.Option
                    key={notetype.Id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-texttertiary bg-primary' : 'text-textprimary',
                        'relative cursor-default select-none py-2 px-3 flex flex-row w-auto gap-1'
                      )
                    }
                    value={notetype}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">                          
                          <span
                            className={classNames(selected ? 'font-semibold' : 'font-normal', 'flex gap-x-1 truncate')}
                          >
                            {NoteTypeIcon ? <NoteTypeIcon className="min-w-[20px] min-h-[20px]" /> : null}
                            {notetype.Name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'inset-y-0 right-0 flex items-center'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                )})}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
      );
}

export default NoteTypeList;
