// Libs & Context
import React, { Fragment, useEffect, useState, Suspense, lazy } from "react";
import { useCampaignContext } from "../contexts/CampaignContext";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useStripeContext } from "../contexts/StripeContext"; // Import the Stripe context
import TutorialPopup from '../components/Infrastructure/TutorialPopup';

// Components
import Loading from '../components/Atoms/Loading';
import Footer from '../components/Infrastructure/Footer.jsx';
import CampaignCard from '../components/Organisms/CampaignCard';
import EmptyStateCampaignList from '../components/Molecules/EmptyStateCampaignList';
const PageHeader = lazy(() => import("../components/Infrastructure/PageHeader"));

const Campaigns = ({themes}) =>
{
  const campaignContext = useCampaignContext();
  const { getCampaigns, setCurrentCampaignId, getThemes } = campaignContext;

  let navigate = useNavigate();
  const { hasAccess } = useStripeContext();

  const pageHeaderActions = [
    { 
      name: 'New Campaign', 
      id: 'btnNewCampaign', 
      icon: 'Plus', 
      onClick: () => { navigate("/campaigns/create") }, 
      group: false,
      isEnabled: hasAccess // Disable the button if no active subscription
    },
  ]

  const CampaignsQuery = useQuery({ queryKey: ["Campaigns"], queryFn: getCampaigns });  
  const ThemesQuery = useQuery({ queryKey: ["Themes"], queryFn: () => getThemes()});

  useEffect(() =>
  {
    setCurrentCampaignId(null);
  }, []);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <PageHeader pageTitle='My Campaigns' actions={pageHeaderActions} subheader={''} />
      </Suspense>

      <TutorialPopup />

      {CampaignsQuery.isLoading && <Loading />}

      {!CampaignsQuery.data || CampaignsQuery.data.length == 0 &&
        <EmptyStateCampaignList />
      }

      {CampaignsQuery.data &&
        <div className="flex h-full w-full overflow-y-auto mx-auto max-w-7xl px-6 lg:px-8 mt-4 pb-1">
          <ul
            role="list"
            className="flex flex-col h-min w-full space-y-3 divide-gray-200 rounded"
          >
            {CampaignsQuery.data?.map((campaign) =>
            {
              let CampaignThemeId = campaign.ThemeId || 0;
              let Theme = ThemesQuery.data?.find((x) => x.Id == CampaignThemeId) || "Default";

              return (<CampaignCard key={campaign.Id} campaign={campaign} theme={Theme} />);
            }
            )
            }
          </ul>
        </div>
      }

      <Footer position={`relative`} />
    </>
  );
};

export default Campaigns;