//import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ComponentType } from "react";
import { useCampaignContext } from "../contexts/CampaignContext";
import { useQuery } from "@tanstack/react-query";
import Loading from "../components/Atoms/Loading";
import { useParams, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";


export const ProtectedRoute = ({ component: Component, PermissionsRequired }) => {
  const campaignContext = useCampaignContext();
  const { currentTheme, getCampaign, getThemes, setCurrentTheme, formTheme, getPartyMembers, checkCampaignAccess } = campaignContext;  

  const {isAuthenticated, loginWithRedirect, user} = useAuth0();

  const { campaignId } = useParams();
  const CampaignId = parseInt(campaignId) || null;
  const campaignEditPattern = /^\/campaigns\/(\d+)\/edit$/;
  const campaignCreatePattern = /^\/campaigns\/create$/;

  if(!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      }
    });
    return <Loading />;
  }
  
  const CampaignQuery = useQuery({ queryKey: ["Campaign"], queryFn: () => getCampaign(CampaignId)});
  const ThemesQuery = useQuery({ queryKey: ["Themes"], queryFn: getThemes});
  const partyMembersQuery = useQuery(["PartyMembers", CampaignId], () => getPartyMembers(CampaignId));  
  const hasAccess = useQuery({ queryKey: ["CampaignAccess", CampaignId], queryFn: () => checkCampaignAccess(CampaignId)});

  // Filter party members based on user.sub
  const filteredPartyMembers = partyMembersQuery.data?.filter(member => member.UserId === user.sub) || [];
  const userInfo = filteredPartyMembers ? filteredPartyMembers[0] : null;

  if(PermissionsRequired && userInfo && PermissionsRequired != userInfo?.Permissions)
    return <Navigate to="/oops"/>

  if(CampaignId && hasAccess.isLoading)
    return <Loading />;

  if(CampaignId && !hasAccess.data)
    return <Navigate to="/oops"/>

  if (window.location.href.includes("campaign"))
  {
    if(CampaignQuery.isLoading || ThemesQuery.isLoading)
      return <Loading />;

    if(window.location.pathname == "/campaigns")
      return <Component themes={ThemesQuery.data || []}/>

    if(CampaignQuery?.data?.length && ThemesQuery?.data?.length)
    {
        let CampaignThemeId = CampaignQuery.data[0].ThemeId || 0;     
        let ThemeName = ThemesQuery.data?.find((x) => x.Id == CampaignThemeId)?.Name;   
        
        if(CampaignThemeId != currentTheme?.Id && ThemeName != undefined)
          setCurrentTheme({Id: CampaignThemeId, Name: CampaignThemeId != 0 ? ThemeName : null});
    }

    if(window.location.pathname.match(campaignEditPattern) || window.location.pathname.match(campaignCreatePattern))
    {
      return (
        <div className={`Themed ${formTheme?.Name ? formTheme?.Name : currentTheme?.Name || "Default"} flex flex-col h-full overflow-hidden`}>
          <Component />
        </div>
      );  
    }

    return (
      <div className={`Themed ${currentTheme?.Name || "Default"} flex flex-col h-full overflow-hidden`}>
        <Component />
      </div>
    );
  }

  return <Component />;
};
