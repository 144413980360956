import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

// Create the context with the initial state
const StripeContext = createContext();

 const API_URL = 'https://inkless.app/api';
//const API_URL = 'http://localhost:8090/api';

// Custom hook to use the Stripe context
export const useStripeContext = () => useContext(StripeContext);

// Provider component
export const StripeProvider = ({ children }) => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [stripeInfo, setStripeInfo] = useState({
        loading: false,
        activeSubscription: false,
        subscriptions: null,
        hasPremium: false,
        hasAccess: false
    });    


    useEffect(() => {
        const fetchStripeInfo = async () => {
            try {
                const token = await getAccessTokenSilently();                
                const userId = user?.sub; // Ensure you have the user's sub                

                let config = {
                    headers: { Authorization: `Bearer ${token}` },   
                    params: {
                        userId: userId,
                    },
                };

                const {data} = await axios.get(`${API_URL}/user`, config);

                const customerId = data[0].CustomerId;    
                const hasPremium = data[0].HasPremium;

                let response;
                try {
                    response = await axios.post(`${API_URL}/check-subscription`, { customerId }, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                } catch (error) {
                    if (error.response && error.response.status === 400) {
                        console.error('Customer ID not found');
                        response = { data: { activeSubscription: false, subscriptions: [] } };
                    } else {
                        throw error;
                    }
                }

                const hasAccess = response.data.activeSubscription || hasPremium;

                setStripeInfo({
                    loading: false,
                    activeSubscription: response.data.activeSubscription,
                    subscriptions: response.data.subscriptions,
                    hasPremium: hasPremium,
                    hasAccess: hasAccess
                });

            } catch (error) {
                console.error('Error fetching Stripe information:', error);
                setStripeInfo({
                    loading: false,
                    activeSubscription: false,
                    subscriptions: [],
                    hasPremium: false,
                });
            }
        };
                
        if (isAuthenticated) {
            fetchStripeInfo();
        }
        else{
            setStripeInfo({
                loading: false,
                activeSubscription: false,
                subscriptions: [],
            });
        }
    }, [isAuthenticated]);

    return (
        <StripeContext.Provider value={stripeInfo}>
            {children}
        </StripeContext.Provider>
    );
};
