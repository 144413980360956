import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';

export default function IconListbox({ 
  value, 
  onChange, 
  options, 
  icon: Icon,
  position = 'right',
  tooltip, 
  iconAlt
}) {
  return (
    <div className="relative hidden sm:block" title={tooltip}>
      <Listbox value={value} onChange={onChange}>
        <div className="relative flex flex-col items-center">
          <Listbox.Button className="relative">
            <Icon 
              aria-label={iconAlt}
              className="w-9 h-9 p-2 border border-border rounded-md ThemeBackground bg-gray text-textprimary hover:text-accent cursor-pointer transition-colors duration-200"
            />
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options 
              className={`absolute ${position}-0 mt-8 w-40 bg-background rounded-md shadow-lg py-1 ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  value={option.id}
                  className={({ active }) =>
                    `${active ? 'text-accent bg-accent/10' : 'text-textprimary'}
                    cursor-pointer select-none relative py-2 pl-3 pr-9`
                  }
                >
                  {option.name}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
} 