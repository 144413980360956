// Libraries
import React, { Fragment, useState, useEffect, useRef, Suspense, lazy } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { Node, Transforms } from 'slate';
import { useCampaignContext } from "../contexts/CampaignContext";
import { Dialog, Transition, Listbox } from '@headlessui/react';
import moment from "moment";
import useUserInformation from "../hooks/useUserInformation";
import { PERMISSIONS } from '../utilities/Constants';
import GifSearchControl from "../components/Organisms/GifSearchControl";
import { useStripeContext } from "../contexts/StripeContext";
import { ClockArrowDown, ClockArrowUp } from 'lucide-react';
import IconListbox from "../components/Molecules/IconListbox";



//Components
import Button from "../components/Atoms/Button";
import Loading from "../components/Atoms/Loading";
import NoteList from "../components/Organisms/NoteList";
import Modal from "../components/Molecules/Modal";
import EmptyStateSession from "../components/Molecules/EmptyStateSession";

const PageHeader = lazy(() => import('../components/Infrastructure/PageHeader'));
const NoteTypeList = lazy(() => import("../components/Atoms/NoteTypeList"));
const SlateEditor = lazy(() => import("../components/Organisms/SlateEditor"));

export default function Session()
{
  const campaignData = useCampaignContext();
  const { useDeleteSession, useAddNote, useAddGifNote, currentCampaignId, setCurrentCampaignId, setCurrentSessionId, currentCampaign, getKeys, getSession, getSessionNotes, getNoteTypes, getKeyWords, extractTextFromNodes, getPartyMembers } = campaignData;

  const stripeContext = useStripeContext();
  const { hasAccess } = stripeContext;

  const { mutate: deleteSession } = useDeleteSession();
  
  const { mutate: AddNote } = useAddNote();
  const {mutate : AddGifNote} = useAddGifNote();

  let navigate = useNavigate();
  const { campaignId, sessionId, noteId: paramNoteId } = useParams();
  const CampaignId = parseInt(campaignId);
  const SessionId = parseInt(sessionId);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const userInfo = useUserInformation(CampaignId);

  const [currentSession, setCurrentSession] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLimitReachedModalOpen, setIsLimitReachedModalOpen] = useState(false);
  const [noteType, setNoteType] = useState(null);
  const [clearEditorValue, setClearEditorValue] = useState(false);
  const initialValue = [{ type: 'paragraph', children: [{ text: '' }] }];  
  const [sortBy, setSortBy] = useState(() => {
    // Try to get saved preference from localStorage, default to 'newest'
    return localStorage.getItem('notesSortPreference') || 'newest';
  });
  const [filterBy, setFilterBy] = useState('all');

  const { isLoading: sessionIsLoading, data: sessionData, error: sessionError } = useQuery({ queryKey: ["Session"], queryFn: () => getSession(CampaignId, SessionId)});
  const SessionNotesQuery = useQuery({ queryKey: ["Notes"], queryFn: () => getSessionNotes(CampaignId, SessionId)});
  const KeysQuery = useQuery({ queryKey: ["Keys"], queryFn: () => getKeys(CampaignId)});
  const NoteTypesQuery = useQuery({ queryKey: ["NoteTypes"], queryFn: () => getNoteTypes(CampaignId)});
  const KeyWordsQuery = useQuery({ queryKey: ["Keywords"], queryFn: () => getKeyWords(CampaignId)});
  

  // Store locally so we can manually manipulate for things like quickly setting sort order.
  let SessionNotesData = SessionNotesQuery.data;

  const noteListRef = useRef(null);

  const handleAddNote = async (noteValue, editor) =>
  {
    let FilteredNoteContent = noteValue[0].children.filter(x => x.text != '');

    let SlateJSON = JSON.stringify(FilteredNoteContent);

    let SearchableText = extractTextFromNodes(FilteredNoteContent);

    FilteredNoteContent = FilteredNoteContent.map((item) =>
    {
      if (item.hasOwnProperty('type') == false)
      {
        return ({ type: 'note', value: item.text });
      }

      return item;
    });

    const JSONValue = JSON.stringify(FilteredNoteContent);

    let NewNote = { noteJSON: JSONValue, NoteTypeId: noteType.Id }

    AddNote(
      {
        event: null,
        note: NewNote,
        slateValue: SlateJSON,
        searchableText: SearchableText,
        sessionId: SessionId
      },
      {
        onSuccess: () => {
          // Scroll to bottom if sorting by oldest first
          if (sortBy === 'oldest' && noteListRef.current) {
            const scrollableContainer = noteListRef.current.querySelector('.overflow-auto');
            if (scrollableContainer) {
              setTimeout(() => {
                scrollableContainer.scrollTo({
                  top: scrollableContainer.scrollHeight,
                  behavior: 'smooth'
                });
              }, 50);
            }
          }
        },
        onError: (error) => {
          console.log(error);
          if (error.response && error.response.status === 403) {
            setIsLimitReachedModalOpen(true);
          }
        }
      }
    );

    Transforms.select(editor, [0]);
    const point = { path: [0, 0], offset: 0 };
    editor.selection = { anchor: point, focus: point }; // this would cause an error on resetting after creating a note with a memo
    editor.children = initialValue; // resets editor to blank state
    setClearEditorValue(true);

    return;
  }

  const handleAddGif = (gif) =>
  {
    AddGifNote(
    {
      campaignId: currentCampaignId,
      noteText: gif.media_formats.gif.url,
      sessionId: SessionId,
      searchText: gif.content_description,
    });

    return;
  }

  const handleDeleteSession = () =>
  {
    deleteSession(currentSession);
    setIsDeleteDialogOpen(false);
    navigate("/campaigns/" + currentCampaignId);
  }
 
  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.addEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  // Checks if the current campaign Id is set. if not, check the current url and try to get that campaign (Ex: user refresh)
  useEffect(() =>
  {
    if (currentCampaignId === null)
    {
      if (CampaignId != undefined)
        setCurrentCampaignId(CampaignId);
    }

    setCurrentSessionId(SessionId);
  }, []);

  useEffect(() =>
  {
    if (!sessionIsLoading && !sessionError && sessionData.length > 0)
    {
      setCurrentSession(sessionData[0]);
    }
  }, [sessionIsLoading, sessionError, sessionData])

  const pageHeaderActions = [
    { name: 'Delete Session', icon: 'Trash', onClick: () => { setIsDeleteDialogOpen(true) }, group: false, visibleTo: PERMISSIONS.Editor },
  ];

  const subheaderInfo = [
    { name: '', data: moment(currentSession?.CreatedDate).format("MMMM Do, YYYY"), icon: 'Calendar' },
    // {name: 'Session End:', data: moment(currentSession.CreatedDate).format("MMMM Do, YYYY"), icon: 'Calendar'}
  ]

  const isMobile = windowWidth <= 768; // Determine if a device is mobile based on the window width;

  const sortOptions = [
    { id: 'newest', name: 'Newest First' },
    { id: 'oldest', name: 'Oldest First' }
  ];

  const filterOptions = [
    { id: 'all', name: 'All Notes' },
    { id: 'text', name: 'Text Only' },
    { id: 'gif', name: 'GIFs Only' }
  ];

  useEffect(() => {
    localStorage.setItem('notesSortPreference', sortBy);
  }, [sortBy]);

  return (
    <>
      <Suspense fallback={<Loading />}><PageHeader actions={pageHeaderActions} subheader={subheaderInfo} pageTitle={currentSession?.Title} titleEditable={true} /></Suspense>
      <div className="overflow-hidden themeBackgroundImage">
        <div className="container mx-auto h-full flex sm:flex-col flex-col-reverse max-w-7xl py-4 px-4 sm:px-6 overflow-hidden">
        
        {userInfo?.Permissions == PERMISSIONS.Editor &&
         <>
            <div className={"flex gap-1 flex-col items-start align-middle sm:flex-row sm:items-end relative"}>
              <div className="flex flex-shrink">
                {NoteTypesQuery.data?.length > 0 && <Suspense fallback={<Loading />}><NoteTypeList NoteTypes={NoteTypesQuery.data} onChange={setNoteType} /></Suspense>}
              </div>

              <div className="flex w-full relative">
              <Suspense fallback={<Loading />}>
                <SlateEditor
                  value={initialValue}
                  onSubmit={handleAddNote}
                  clearValue={clearEditorValue}
                  keysQuery={KeysQuery}
                  keyWordsQuery={KeyWordsQuery}
                  placeHolder={isMobile && `Type Enter/Return to submit a note.`} />
              </Suspense>

              {/* {hasAccess == true && <GifSearchControl onClick={handleAddGif} />} */}
              <GifSearchControl onClick={handleAddGif} />

              </div>

              {/* <IconListbox
                value={filterBy}
                onChange={setFilterBy}
                options={filterOptions}
                icon={Filter}
                tooltip="Filter notes"
                iconAlt="Filter icon"
              /> */}

              <IconListbox
                value={sortBy}
                onChange={setSortBy}
                options={sortOptions}
                icon={sortBy === 'newest' ? ClockArrowDown : ClockArrowUp}
                tooltip="Sort notes"
                iconAlt="Sort icon"
              />              
            </div>            
          </>
        }
        
        {(!SessionNotesData || SessionNotesData?.length == 0) && <EmptyStateSession />}

        {SessionNotesData?.length > 0 && 
          <NoteList 
            CampaignId={CampaignId} 
            KeyWordsQuery={KeyWordsQuery} 
            KeysQuery={KeysQuery} 
            NoteTypesQuery={NoteTypesQuery} 
            SessionNotesData={SessionNotesData} 
            paramNoteId={paramNoteId}
            sortBy={sortBy}
            ref={noteListRef}
          />
        }
        </div>
      </div>

      <Modal
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      >
        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-textprimary mb-4">
          Delete Session
        </Dialog.Title>

        <p>
          Are you sure you want to delete this session?
        </p>

        <div className="mt-4 flex justify-end gap-2">          
          <Button label={"Cancel"} bgColor='bg-background' textColor="text-textprimary" border="border-border" onClick={() => setIsDeleteDialogOpen(false)} />
          <Button label={"Delete"} onClick={() => handleDeleteSession(false)} />
        </div>
      </Modal>

      <Modal
        isOpen={isLimitReachedModalOpen}
        onClose={() => setIsLimitReachedModalOpen(false)}
      >
        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-textprimary mb-4">
          Note Limit Reached
        </Dialog.Title>

        <p>
          You have reached your note limit. Please consider supporting Inkless and get unlimited access to all features.
        </p>

        <div className="mt-4 flex justify-end gap-2">
          <Button label={"Close"} bgColor='bg-background' textColor="text-textprimary" border="border-border" onClick={() => setIsLimitReachedModalOpen(false)} />
          <Button label={"Upgrade"} onClick={() => navigate("/pricing")} />
        </div>
      </Modal>
    </>
  );
}