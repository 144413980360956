import axios from 'axios';
import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

import { useAuth0 } from "@auth0/auth0-react";

// Make sure to call `loadStripe` outside of a component's render to avoid
// recreating the `Stripe` object on every render.
// This is a public sample test API key.
// Don't submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe("pk_live_51PKTWn2NRmM6W80weQNCI3YLOqKw1smfiNQ458e2312IcV0hhsa36otDL0QVgIHJLH8bPsTC4m4XRIYKap6zg5Xu00Cs6vjmzO");

const Checkout = () => {
  
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const fetchClientSecret = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`
      };

      // Create a Checkout Session
      const sessionResponse = await axios.post("https://inkless.app/api/create-checkout-session",
        { email: user.email },
        { headers }
      );
      const { customerId, clientSecret } = sessionResponse.data;

      const updateResponse = await axios.post("https://inkless.app/api/update-customer-id",
        {
          userId: user.sub,
          customerId: customerId
        },
        { headers }
      );     

      return clientSecret;
      
    } catch (error) {
      console.error('Error in checkout process:', error);
    }
  }, []);

  const options = {fetchClientSecret};

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

export default Checkout;