import React, { useState, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient, useMutation} from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

const axios = require("axios");

export const CampaignContext = React.createContext();
export const useCampaignContext = () => useContext(CampaignContext);

export const CampaignContextProvider = ({ children }) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();    

  // Campaigns  
  const [currentCampaignId, setCurrentCampaignId] = useState(null);  

  // Session
  const [currentSessionId, setCurrentSessionId] = useState(null);

  // Players  
  const [currentPlayers, setCurrentPlayers] = useState([]);

  // Search
  const [selectedSearchResult, setSelectedSearchResult] = useState();

  // Themes
  const [currentTheme, setCurrentTheme] = useState(null);
  const [formTheme, setFormTheme] = useState(null);
  
  const API_IP = "https://inkless.app/api";
  

  //#region Campaigns

  const getCampaign = async (CampaignId) => {
    if (isAuthenticated == false) return;

    if(!CampaignId) return [];

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(API_IP + "/campaigns/" + CampaignId, config);

    return data;
  }

  const getCampaigns = async () => {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };    

    const {data} = await axios.get(API_IP + "/campaigns", config);

    return data;
  };

  const checkCampaignAccess = async (CampaignId) => {
    if (isAuthenticated == false) return false;

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };
    const {data} = await axios.get(API_IP + "/campaigns/" + CampaignId + "/access", config);
    return data;
  }

  const addCampaign = async (campaign) => {
    if (campaign.Name === "") return;
    const token = await getAccessTokenSilently();

    const res = await axios.post(
      API_IP + "/campaigns",
      {
        name: campaign.Name,
        startDate: campaign.StartDate,
        ThemeId: campaign.ThemeId,
        userId: user.sub,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    
    let returnId = res.data[0][0].Id;        
    
    return returnId;
  };

  const useAddCampaign = useMutation(addCampaign, {
      onSuccess: (data) => {                
        queryClient.invalidateQueries('Campaigns');
        return data;
      }
    });

  const deleteCampaign = async (campaignId) => {
    const token = await getAccessTokenSilently();

    const res = await axios
      .delete(API_IP + "/campaigns/" + campaignId, {
        headers: { Authorization: `Bearer ${token}` },
      });
  };

  const useDeleteCampaign = () => {
    return useMutation(deleteCampaign, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Campaigns');
      }
    });
  }

  const updateCampaign = async (campaign, players) => {
    const token = await getAccessTokenSilently();

    let playersString = null;

    if (players?.length > 0)
      playersString = players.map((x) => x.CharacterName).join("|");

    return await axios.patch(
      API_IP + "/campaigns/",
      {
        id: campaign.Id,
        name: campaign.Name,
        startDate: campaign.StartDate,
        players: playersString,
        DefaultQuestNoteTypeId: campaign.DefaultQuestNoteTypeId,
        ThemeId: campaign.ThemeId,
        userId: user.sub,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  const useUpdateCampaign = () => {
    return useMutation(updateCampaign, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Campaigns');
      }
    });
  }

  const joinCampaign = async (campaignId, inviteCode, permission) => {
    const token = await getAccessTokenSilently();    
    

    const res = await axios.post(
      `${API_IP}/campaigns/join`,
      {
        campaignId: campaignId,
        inviteCode: inviteCode,
        permission: permission,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    return res;
  };
  //#endregion

  //#region Notes & Words  
  const addNote = async (props) => {
    if(props.event) props.event.preventDefault(); // Needed to prevent session refresh but still maintain form "enter".

    if (props.note.noteJSON === "[]") return;

    const token = await getAccessTokenSilently();

    try {
        const response = await axios.post(
            API_IP + "/notes",
            {
                noteJSON: props.note.noteJSON,
                slateJSON: props.slateValue,
                searchableText: props.searchableText,
                sessionId: props.sessionId,
                userId: user.sub,
                campaignId: currentCampaignId,
                NoteTypeId: props.note.NoteTypeId,
            },
            { headers: { Authorization: `Bearer ${token}` } }
        );
        return response;
    } catch (error) {
        throw error;
    }
  };

  const useAddNote = () => {
    return useMutation(addNote, {
        onSuccess: () => {                
            queryClient.invalidateQueries('Notes');
            queryClient.invalidateQueries('Keywords');
        },
        onError: (error) => {
            throw error;
        }
    });
  }

  const addGifNote = async (props) => {
    if(props.event) props.event.preventDefault(); // Needed to prevent session refresh but still maintain form "enter".

    if (props.noteText === "") return;

    const token = await getAccessTokenSilently();

    return await axios
      .post(
        API_IP + "/gifnotes",
        {
          campaignId: currentCampaignId,
          noteText: props.noteText,          
          sessionId: props.sessionId,
          userId: user.sub,
          searchText: props.searchableText,          
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  };

  const useAddGifNote = () => {
    return useMutation(addGifNote, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Notes');
      }
    });
  }

  const updateNote = async (UpdatedNote) => {
    const token = await getAccessTokenSilently();
    return await axios.patch(
      API_IP + "/notes",
      {
        noteId: UpdatedNote.Id,
        noteJSON: UpdatedNote.noteJSON,
        slateJSON: UpdatedNote.slateValue,
        searchableText: UpdatedNote.searchableText,
        noteTypeId: UpdatedNote.noteTypeId,
        isDeleted: UpdatedNote.isDeleted || null, 
        campaignId: currentCampaignId,       
        userId: user.sub,        
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const useUpdateNote = () => {
    return useMutation(updateNote, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Notes');
      }
    });
  }

  const deleteNote = async (noteId) => {
    const token = await getAccessTokenSilently();

    return await axios.delete(API_IP + "/notes/" + noteId, {
        headers: { Authorization: `Bearer ${token}` },
      });
  };

  const useDeleteNote = () => {
    return useMutation(deleteNote, {
      onSuccess: () => {
        queryClient.invalidateQueries('Notes');
      }
    })
  }
  
  const updateSortOrder = async (update) => {
    try {
      const token = await getAccessTokenSilently();

      let config = { headers: { Authorization: `Bearer ${token}` } };

      await axios.put(`${API_IP}/notes/sort`, {
        noteId: update.noteId,
        sortOrder: update.newSortOrder
      }, config);
    } catch (error) {
      console.error('Error updating sort order:', error);
    }
  };

  const useUpdateSortOrder = (options = {}) => {
    return useMutation(updateSortOrder, {
      onSuccess: () => {
        queryClient.invalidateQueries('SessionNotes');
      }
    })
  }

  // Function to extract raw text from a list of nodes
  const extractTextFromNodes = (nodes) => {
    const extractTextFromNode = (node) => {
      if (node.text) {
        return node.text.trim();
      } else if (node.type == "mention") {
        return node.value.trim();
      }
      return '';
    };

    return nodes.map(extractTextFromNode).join(' ');
  };

  //#endregion

  //#region Sessions

  const getSession = async  (CampaignId, SessionId) => {
    if(!CampaignId || CampaignId == null)
      return;

    if(!SessionId || SessionId == null)
      return;

    const token = await getAccessTokenSilently();

    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(`${API_IP}/sessions/${CampaignId}/${SessionId}`, config);

    return data;
  }
  
  const getSessions = async (CampaignId) => {

    if(!CampaignId || CampaignId == null)
      return;

    const token = await getAccessTokenSilently();

    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(API_IP + "/sessions/" + CampaignId, config);

    return data;
  };

  const getSessionNotes = async (CampaignId, SessionId) => {
    const token = await getAccessTokenSilently();

    if(!CampaignId) return;

    if(!SessionId) return;

    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(`${API_IP}/sessionnotes/${CampaignId}/${SessionId}`, config);

    return data;
  };

  const getSessionWords = async (CampaignId, SessionId) => {
    
    const token = await getAccessTokenSilently();

    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(`${API_IP}/sessionwords/${CampaignId}/${SessionId}`, config);
    
    return data;    
  }

  const addSession = async (event, CampaignId) => {
    if (event) event.preventDefault();

    if (isAuthenticated === false) return;

    const token = await getAccessTokenSilently();
    
    return await axios.post(`${API_IP}/sessions`,
        {
            campaignId: CampaignId,
            userId: user.sub,
        },
        { headers: { Authorization: `Bearer ${token}` } }
    );
};

  const deleteSession = async (Session) => {
    const token = await getAccessTokenSilently();

    return await axios
      .delete(`${API_IP}/sessions/${Session.Id}`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { campaignId: currentCampaignId },
        });
  };

  const useDeleteSession = () => {
    return useMutation(deleteSession, {
      onSuccess: () => {
        queryClient.invalidateQueries('Sessions');
      }
    });
  }

  const updateSession = async (SessionTitle) => {
    if (isAuthenticated === false) return;

    const token = await getAccessTokenSilently();

    const res = axios.patch(
      API_IP + "/sessions",
      {
        sessionId: currentSessionId,
        Title: SessionTitle,
        userId: user.sub,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const useUpdateSession = () => {
    return useMutation(updateSession, {
      onSuccess: () => {
        queryClient.invalidateQueries('Sessions');
      }
    });
  }  

  const handleSessionIdChange = () => {
    if (sessions) {
      let session = sessions.find(
        (session) => session.id === currentSession.SessionId
      );
      setCurrentSession(session);
    }
  };

  const handlePaginationChange = (sessionIndex) => {
    if (sessions[sessionIndex - 1] === undefined) return;

    setCurrentSession(sessions[sessionIndex - 1]);    
  };

  //#endregion

  //#region Quests
  const getQuests = async (CampaignId, NoteTypeId) => {
    const token = await getAccessTokenSilently();

    if(!CampaignId) return [];

    if(!NoteTypeId) return [];

    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(`${API_IP}/quests/${CampaignId}/${NoteTypeId}`, config);

    return data;
  };

  const updateQuest = async (QuestObj) => {
    const token = await getAccessTokenSilently();
    return await axios.patch(
      API_IP + "/quests",
      {
        noteId: QuestObj.NoteId,
        isChecked: QuestObj.Checked,
        campaignId: currentCampaignId,       
        userId: user.sub,        
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };

  const useUpdateQuest = () => {
    return useMutation(updateQuest, {
      onSettled: async () => {
        return await queryClient.invalidateQueries('Quests');
      }
    });
  }  

  //#endregion

  //#region Keys

  const getKeys = async (campaignId = currentCampaignId) => {
    if (isAuthenticated == false) return [];

    if (!campaignId) return [];

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(API_IP + "/keys/" + campaignId, config);

    return data;
  };

  const addKey = async (Key) => {   
    if (Key.Name === "" || Key.Color === "") return;

    const token = await getAccessTokenSilently();

    return await axios.post(API_IP + "/keys",
        {
          Name: Key.Name,
          Color: Key.Color,
          Style: parseInt(Key.Style) ?? 0,
          BorderStyle: parseInt(Key.BorderStyle) ?? 0,
          UserId: user.sub,
          CampaignId: currentCampaignId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  };

  const useAddKey = () => {
    return useMutation(addKey, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Keys');
      }
    });
  }

  const updateKey = async (Key) => {
    const token = await getAccessTokenSilently();

    return await axios.patch(API_IP + "/keys",
        {
          KeyId: Key.Id,
          Name: Key.Name,
          Color: Key.Color,
          Style: parseInt(Key.Style),
          BorderStyle: parseInt(Key.BorderStyle),
          UserId: user.sub,
          CampaignId: currentCampaignId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  };

  const useUpdateKey = () => {
    return useMutation(updateKey, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Keys');
      }
    });
  }

  const deleteKey = async (Key) => {
    const token = await getAccessTokenSilently();

    return await axios
      .delete(API_IP + "/keys/" + Key.Id, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(function () {
        getKeys();
      });
  };

  const useDeleteKey = () => {
    return useMutation(deleteKey, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Keys');
      }
    });
  }
  //#endregion

  //#region NoteTypes

  const getNoteTypes = async (campaignId = currentCampaignId) => {
    if (isAuthenticated == false) return [];

    if(!campaignId) return [];   

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(API_IP + "/notetypes/" + campaignId, config);

    return data;
  };

  const addNoteType = async (NoteType) => {

    const token = await getAccessTokenSilently();

    return axios.post(API_IP + "/notetypes",
    {
      Name: NoteType.Name,
      Icon: NoteType.Icon,
      Hotkey: NoteType.Hotkey,
      CampaignId: currentCampaignId,
      UserId: user.sub,      
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  const useAddNoteType = () => {
    return useMutation(addNoteType, {
      onSuccess: () => {                
        queryClient.invalidateQueries('NoteTypes');
      }
    });
  }

  const updateNoteType = async (NoteType) => {

    const token = await getAccessTokenSilently();

    return await axios.patch(API_IP + "/notetypes",
    {
      NoteTypeId: NoteType.Id,
      Name: NoteType.Name,
      Icon: NoteType.Icon,
      Hotkey: NoteType.Hotkey,
      CampaignId: currentCampaignId,
      UserId: user.sub,      
    },
    {
      headers: { Authorization: `Bearer ${token}` },
    });
  }

  const useUpdateNoteType = () => {
    return useMutation(updateNoteType, {
      onSuccess: () => {                
        queryClient.invalidateQueries('NoteTypes');
      }
    });
  }

  const deleteNoteType = async (NoteType) => {
    const token = await getAccessTokenSilently();

    return await axios.delete(API_IP + "/notetypes/" + NoteType.Id, {
        headers: { Authorization: `Bearer ${token}` },
      });
  };

  const useDeleteNoteType = () => {
    return useMutation(deleteNoteType, {
      onSuccess: () => {                
        queryClient.invalidateQueries('NoteTypes');
      }
    });
  }

  //#endregion

  //#region Keywords & KeyNotes
  const getKeyWords = async (campaignId = currentCampaignId) => {
    if (isAuthenticated == false) return [];

    if(!campaignId) return [];

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(API_IP + "/keywords/" + campaignId, config);

    return data;
  };

  const addKeyWord = async (keyID, noteID, wordId) => {

    const token = await getAccessTokenSilently();

    return await axios.post(API_IP + "/keywords",
        {
          keyid: keyID,
          noteid: noteID,
          wordid: wordId,
          campaignId: currentCampaignId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  };

  const useAddKeyword = () => {
    return useMutation(addKeyWord, {
      onSuccess: () => {                
        queryClient.invalidateQueries('Keywords');
      }
    });
  } 

  const getDistinctKeyWords = async (SearchText = null, TagTypeString = null, campaignId = currentCampaignId) => {
    if (isAuthenticated == false) return [];

    if(!campaignId) return [];

    const token = await getAccessTokenSilently();

    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        CampaignId: campaignId,
        SearchText: SearchText,
        TagTypeString: TagTypeString || null,        
      },
    };

    const {data} = await axios.get(API_IP + "/distinctkeywords/" + campaignId, config);

    return data;
  };

  const updateKeyword = async (updateObject, campaignId = currentCampaignId) => {
    if (isAuthenticated == false) return;
    if(!campaignId) return;
    
    const token = await getAccessTokenSilently();

    return await axios.patch(API_IP + "/keywords",
      {
        CampaignId: campaignId,
        SelectedKeyId: updateObject.SelectedKeyId,
        SelectedWord: updateObject.SelectedWord,
        NewKeyId: updateObject.NewKeyId,
        NewWord: updateObject.NewWord,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  }
  
  const useUpdateKeyWord = () => {
    return useMutation(updateKeyword, {
      onSuccess: () => {                
        queryClient.invalidateQueries('DistinctKeyWords');
        queryClient.invalidateQueries('KeyWords');
      }
    });
  }
  //#endregion

  //#region Search
  const getSearchResults = async(SearchParams) => {

    if (isAuthenticated == false) return [];

    const token = await getAccessTokenSilently();
    
    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        SearchText: SearchParams.SearchText,
        KeywordsString: SearchParams.KeywordsString || null,
        KeysString: SearchParams.KeysString || null,
        NoteTypesString: SearchParams.NoteTypesString || null,
        CampaignId: currentCampaignId,
        UserId: user.sub,
      },
    };

    const {data} = await axios.get(API_IP + "/search", config);

    return data;       
  }
  //#endregion

  //#region Players

  const getPlayers = async(CampaignIds) =>
  {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();
    let CampaignIdsString = CampaignIds.join(",");

    let config = {
      headers: { Authorization: `Bearer ${token}` },
      params: { CampaignIds: CampaignIdsString },
    };

    const {data} = await axios.get(API_IP + "/players/", config);

    return data;
  };

  const addPlayers = async (players, campaignId) => {
    if (players.length == 0) return;

    const token = await getAccessTokenSilently();

    let playersString = players.map((x) => x.CharacterName).join("|");

    axios.post(
      API_IP + "/players",
      {
        Players: playersString,
        CampaignId: campaignId,
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  };
  //#endregion

  //#region PartyMembers

  const getPartyMembers = async(campaignId = currentCampaignId) =>
  {
    if (isAuthenticated == false) return [];

    if(!campaignId) return [];

    const token = await getAccessTokenSilently();

    let config = { headers: { Authorization: `Bearer ${token}` },
    params: {
      CampaignId: campaignId,
      UserId: user.sub,
    }, };

    const {data} = await axios.get(API_IP + "/partymembers/", config);

    return data;
  };

  const updatePartyMember = async(pmObject) =>
  {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();

    return await axios.patch(API_IP + "/partymembers",
        {
          PartyMemberId: pmObject.PartyMemberId,
          CampaignId: pmObject.CampaignId,
          UserId: user.sub,
          Permissions: pmObject.Permissions,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  };

  const useUpdatePartyMember = () => {
    return useMutation(updatePartyMember, {
      onSuccess: () => {                
        queryClient.invalidateQueries('PartyMembers');
      }
    });
  }

  const deletePartyMember = async(pmObject) =>
  {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();

    return await axios.delete(API_IP + "/partymembers",        
        {
          headers: { Authorization: `Bearer ${token}` },
          data: {
            PartyMemberId: pmObject.PartyMemberId,
            CampaignId: pmObject.CampaignId,
            UserId: user.sub,
          },
        },        
      );
  };

  const useDeletePartyMember = () => {
    return useMutation(deletePartyMember, {
      onSuccess: () => {                
        queryClient.invalidateQueries('PartyMembers');
      }
    });
  }

  //#endregion

  //#region Settings
  const getSettings = async () => {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(API_IP + "/settings/", config);

    return data;
  };

  //#region Themes

  const getThemes = async () => {
    if (isAuthenticated == false) return [];

    const token = await getAccessTokenSilently();
    let config = { headers: { Authorization: `Bearer ${token}` } };

    const {data} = await axios.get(API_IP + "/themes/", config);

    return data;
  };

  //#endregion

  //#region Gifs

  var getGifCategories = async () => {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();

    let config = { headers: { Authorization: `Bearer ${token}` } };

    // use axios to call my gif search API with the search term
    const {data} = await axios.get(API_IP + '/gifcategories', config);

    return data;
  };

  var getGifSearchResults = async (searchTerm) => {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();    
    
    const {data} = await axios.get('https://inkless.app/api/gifsearch', {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        searchTerm: searchTerm,
        limit: 10
      }
    });

    return data;
  }

  var getGifCategoryResults = async (category) => {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();
    
    const {data} = await axios.get('https://inkless.app/api/gifsearch', {
      headers: { Authorization: `Bearer ${token}` },
      params: {
        searchTerm: category,
        limit: 10
      }
    });

    return data;
  }

  //#endregion

  //#region Users

  var getUser = async () => {
    if (isAuthenticated == false) return;

    const token = await getAccessTokenSilently();

    const {data} = await axios.get(API_IP + '/user', {
      headers: { Authorization: `Bearer ${token}` }
    });

    return data;
  }

  //#endregion

  const getStyle = (state) => {
    if(!state)
      return;

    let styleClasses = [];

    if (state.Style & 1) styleClasses.push("font-bold");
    if (state.Style & 2) styleClasses.push("italic");
    if (state.Style & 4) styleClasses.push("text-decoration-line: underline");

    if (state.BorderStyle & 1) styleClasses.push("border-2 border-solid border-text-primary");
    if (state.BorderStyle & 2) styleClasses.push("border-4 border-double border-text-primary");
    if (state.BorderStyle & 4) styleClasses.push("border-2 border-dashed border-text-primary");
    if (state.BorderStyle & 8) styleClasses.push("border-2 border-dotted border-text-primary");

    return styleClasses.join(" ");
  };


  const CampaignContextData = {

    setCurrentCampaignId,
    setCurrentSessionId,


    addCampaign,
    deleteCampaign,
    useAddCampaign,
    useDeleteCampaign,
    updateCampaign,
    useUpdateCampaign,
    joinCampaign,
    currentCampaignId,
    getCampaign,
    getCampaigns,
    checkCampaignAccess,

    addNote,
    useAddNote,
    addGifNote,
    useAddGifNote,
    deleteNote,    
    useUpdateNote,
    useDeleteNote,
    updateSortOrder,
    useUpdateSortOrder,
    extractTextFromNodes,
    
    getSession,
    getSessions,    
    addSession,
    deleteSession,
    useDeleteSession,
    handleSessionIdChange,
    handlePaginationChange,    
    useUpdateSession,

    getKeys,
    addKey,
    updateKey,
    deleteKey,

    useAddKey,
    useUpdateKey,
    useDeleteKey,
    
    getSessionNotes,
    getSessionWords,

    getQuests,
    updateQuest,
    useUpdateQuest,

    addNoteType,
    getNoteTypes,
    updateNoteType,
    deleteNoteType,

    useAddNoteType,
    useUpdateNoteType,
    useDeleteNoteType,    
    
    getKeyWords,
    addKeyWord,
    getDistinctKeyWords,
    updateKeyword,
    useUpdateKeyWord,

    getSearchResults,

    getPlayers,
    currentPlayers,
    setCurrentPlayers,

    getPartyMembers,
    updatePartyMember,
    deletePartyMember,
    useUpdatePartyMember,
    useDeletePartyMember,
    
    getSettings,    

    getThemes,
    currentTheme,
    setCurrentTheme,
    formTheme,
    setFormTheme,

    selectedSearchResult,
    setSelectedSearchResult,

    getGifCategories,
    getGifSearchResults,
    getGifCategoryResults,

    getUser,

    getStyle,
  };

  return (
    <CampaignContext.Provider value={CampaignContextData}>
      {children}
    </CampaignContext.Provider>
  );
};
