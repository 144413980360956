import { useState, useEffect } from 'react';
import NoteTypeList from '../Atoms/NoteTypeList';
import TagImage from '../../images/tutorial/tags.png';
import TagImage2 from '../../images/tutorial/tags2.png';
import LegendImage from '../../images/tutorial/legend.png';
import AtlasImage from '../../images/tutorial/atlas.webp';
import FinalImage from '../../images/tutorial/final.jpeg';

const NoteTypesData = [
  { Id: 1, Name: "Note", Icon: "" },
  { Id: 2, Name: "Quote", Icon: "Quote" },
  { Id: 3, Name: "Memory", Icon: "Heart" },
  { Id: 4, Name: "Quest", Icon: "Quest" },  
];

const SearchComponent = () => {
  return (
    <span
      id="search"
      onClick={() => {}}
      name="search"
      className="w-52 rounded-md shadow shadow-shadow cursor-pointer bg-gray py-2 pl-10 pr-3 leading-5
  text-textprimary placeholder-gray-400 focus:border-white focus:bg-white focus:text-gray-900 focus:outline-none focus:ring-white sm:text-sm flex justify-between items-center"                            
>
  <span>Search</span>
  <kbd className="inline-flex items-center rounded px-1.5 text-xs font-medium text-slate-400">
        Ctrl K
      </kbd>
    </span>
  );
}

const tutorialSteps = [
  {
    title: "Welcome! 👋",
    content: "Let's take a quick tour to help you start taking notes like a pro.",
    image: AtlasImage,
    imageSize: "large"
  },
  {
    title: "Create a Campaign",
    content: [
      "Click the New Campaign button after this tutorial, once you're ready to get started.",
      "A first session will be created for you.",
      'A "session" is each time you gather to play with your friends.'
    ]
  },
  {
    title: "Notes",
    content: [
      "Notes are the backbone of your campaign.",
      'Not all notes are made equal so we\'ve made note "types" to distinguish them.',
      "A note's type can be changed by using the dropdown to the left of the note input field."
    ],
    component: <NoteTypeList NoteTypes={NoteTypesData} onChange={() => {}} />
  },
  {
    title: "Tags",
    content: [
      "Use tags to categorize special or memorable things inside your notes.",
      "These can be anything such as a Player Character, an NPC, a location, or a magic item.",
      "You can create a new tag by typing \"@\", typing the name of the tag, use the arrow keys to select the tag type, and then press enter."
    ],
    image: TagImage,
    imageSize: "normal"
  },
  {
    title: "Tags",
    content: [
      "Once a tag is created, it will be saved to your campaign and can be used in any note.",
      "Continue typing after \"@\" to filter tags by name and select them to use them again in your notes."
    ],
    image: TagImage2,
    imageSize: "large"
  },
  {
    title: "Legend",
    content: [
      `You can create or edit new Tag Types and Note Types on the Legend tab of your campaign.`      
    ],
    image: LegendImage,
    imageSize: "normal"
  },
  {
    title: "Search",
    content: [
      `Use the search bar to search campaign-wide. You can also use the Ctrl + K shortcut to open the search bar.`,
      `You can also use the search bar to search for tags by typing \"@\" followed by the name of the tag.`
    ],
    component: <SearchComponent  />
  },
  {
    title: "Ready to Go! 🚀",
    content: "You're all set! Those are the basics but there's a lot more to explore!",
    image: FinalImage,
    imageSize: "large"
  }
];

const TutorialPopup = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    // Check if user has seen the tutorial
    const hasSeenTutorial = localStorage.getItem('hasSeenTutorial');
    if (hasSeenTutorial) {
      setIsOpen(false);
    }
  }, []);

  const handleNext = () => {
    if (currentStep === tutorialSteps.length - 1) {
      // Last step - close tutorial and save to localStorage
      setIsOpen(false);
      localStorage.setItem('hasSeenTutorial', 'true');
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSkip = () => {
    setIsOpen(false);
    localStorage.setItem('hasSeenTutorial', 'true');
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const renderContent = (content) => {
    if (Array.isArray(content)) {
      return (
        <ul className="list-disc pl-5 space-y-2 text-gray-600">
          {content.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      );
    }
    return <p className="text-gray-600">{content}</p>;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-background border border-border rounded-lg p-8 max-w-4xl w-full mx-4 shadow-xl">        
        {/* Step tracker dots */}
        <div className="flex justify-center mb-6">
          <div className="flex space-x-2">
            {tutorialSteps.map((_, index) => (
              <div
                key={index}
                className={`h-2 w-2 rounded-full ${
                  index === currentStep ? 'bg-accent' : 'bg-border'
                }`}
              />
            ))}
          </div>
        </div>

        <div className="flex gap-8 mb-4">
          {/* Left side - Text content */}
          <div className={`flex-1 ${!tutorialSteps[currentStep].image && !tutorialSteps[currentStep].component ? 'mx-auto' : ''}`}>
            <div className="min-h-[260px]">
              <h2 className="text-2xl font-bold mb-4">
                {tutorialSteps[currentStep].title}
              </h2>
              {renderContent(tutorialSteps[currentStep].content)}
            </div>
          </div>

          {/* Right side - Visual content */}
          {(tutorialSteps[currentStep].image || tutorialSteps[currentStep].component) && (
            <div className="flex-1">
              <div className="min-h-[200px] pt-2 flex items-center justify-center">
                {tutorialSteps[currentStep].image && (
                  <img 
                    src={tutorialSteps[currentStep].image} 
                    alt={tutorialSteps[currentStep].title}
                    className={`object-contain rounded border border-border shadow-lg
                      ${tutorialSteps[currentStep].imageSize === 'large' ? 'h-64' : 'h-48'}`}
                  />
                )}
                {tutorialSteps[currentStep].component && (
                  <div className="w-fit">
                    {tutorialSteps[currentStep].component}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        
        {/* Bottom controls */}
        <div className="flex justify-end space-x-3">
          {currentStep === 0 ? (
            <button
              onClick={handleSkip}
              className="text-textprimary hover:text-textaccent hover:bg-accent px-4 py-2 rounded-lg transition-colors"
            >
              Skip Tutorial
            </button>
          ) : (
            <button
              onClick={handleBack}
              className="text-textsecondary hover:text-textprimary hover:border-textprimary border border-border px-4 py-2 rounded-lg transition-colors"
            >
              Back
            </button>
          )}
          <button
            onClick={handleNext}
            className="bg-primary hover:bg-primaryhover text-texttertiary px-6 py-2 rounded-lg transition-colors"
          >
            {currentStep === tutorialSteps.length - 1 ? 'Finish' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TutorialPopup; 