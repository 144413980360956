import { MapIcon } from '@heroicons/react/24/outline';

export default function Oops() {
    return (
        <div id="OopsBody" className="min-h-screen">
            <div className="flex flex-col items-center justify-center min-h-[80vh] text-center px-8">
                <MapIcon 
                    className="w-16 h-16 text-gray-600 mb-6"
                />
                <h1 className="text-4xl font-bold text-gray-800 mb-4">
                    Perception check failed
                </h1>
                <p className="text-xl text-gray-600 mb-8">
                    Oops... looks like something went wrong.
                </p>
                <a 
                    href="/" 
                    className="px-6 py-3 bg-primary text-white rounded-md 
                             hover:bg-primaryhover transition-colors duration-300"
                >
                    Return to Home
                </a>
            </div>
        </div>
    );
}